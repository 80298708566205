/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "firebase/analytics"
import "firebase/remote-config"
import firebase from "gatsby-plugin-firebase";

firebase.remoteConfig().settings.minimumFetchIntervalMillis = 1000 * 60 * 5;
