// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from '@chakra-ui/react';

const baseColors = {
  black: '#021729',
  blackAlpha6: 'rgba(2, 23, 41, 0.06)',
  blackAlpha12: 'rgba(2, 23, 41, 0.12)',
  emerald100: '#358596',
  emerald200: '#27788A',
  emerald300: '#1C6D80',
  grey100: '#F9F9F9',
  grey400: '#B2BBC2',
  grey600: '#7D8A95',
  grey700: '#6A7985',
  lightEmerald: '#EFF6F7',
  lightRed: '#F6E8E8',
  red: '#E02D43',
  white: '#FFFFFF',
};

const colorSystem = {
  bgAccent: baseColors.emerald100,
  bgAccentHover: baseColors.emerald200,
  bgAccentPressed: baseColors.emerald300,
  bgBlackAlpha6: baseColors.blackAlpha6,
  bgBlackAlpha12: baseColors.blackAlpha12,
  bgLightAccent: baseColors.lightEmerald,
  bgLightNegative: baseColors.lightRed,
  bgLightNegativeHover: baseColors.lightRed,
  bgLightNegativePressed: baseColors.lightRed,
  bgNegative: baseColors.red,
  bgPrimary: baseColors.white,
  bgSecondary: '#F2F4F5',
  bgSuperBlack: '#010B14',
  bgTertiary: baseColors.grey100,
  brdAccent: baseColors.emerald100,
  brdBlackAlpha12: baseColors.blackAlpha12,
  brdNegative: baseColors.red,
  cntAccent: baseColors.emerald100,
  cntEmpty: baseColors.grey400,
  cntNegative: baseColors.red,
  cntOnColor: baseColors.white,
  cntPrimary: baseColors.black,
  cntSecondary: baseColors.grey700,
  cntTertiary: baseColors.grey600,
};

const cfChakraTheme = extendTheme({
  breakpoints: {
    sm: '768px',
    base: '0em',
    md: '1024px',
    lg: '1280px',
    xl: '1440px',
    '2xl': '1920px',
  },
  colors: {
    cf: {
      ...baseColors,
      ...colorSystem,
    },
    teal: {
      500: colorSystem.bgAccent,
      600: colorSystem.bgAccentHover,
      700: colorSystem.bgAccentPressed,
    },
  },
  fontSizes: {
    xs: '0.75rem', // 12
    sm: '0.875rem', // 14
    md: '1rem', // 16
    lg: '1.125rem', // 18
    xl: '1.25rem', // 20
    '2xl': '1.375rem', // 22
    '3xl': '1.5rem', // 24
    '4xl': '1.75rem', // 28
    '5xl': '2rem', // 32
    '6xl': '2.25rem', // 36
    '7xl': '2.5rem', // 40
    '8xl': '2.75rem', // 44
    '9xl': '3rem', // 48
  },
  lineHeights: {
    shorter: '1rem',
    short: '1.25rem',
    base: '1.5rem',
    tall: '1.75rem',
    taller: '2rem',
  },
  styles: {
    global: {
      'html, body': {
        bg: '#F9F9F9',
        color: '#021729',
        height: '100%',
        overscrollBehavior: 'none',
      },
      '#root, .App': {
        height: '100%',
      },
      '@keyframes pulse': {
        '0%,100%': {
          opacity: 1,
        },
        '50%': {
          opacity: 0.4,
        },
      },
    },
  },
  sizes: {
    container: {
      xs: '280px',
    },
    0.25: '0.0625rem',
    0.75: '0.1875rem',
    11: '2.75rem',
  },
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Inter, sans-serif',
  },
  radii: {
    md: '8px',
    sm: '4px',
  },
  components: {
    Avatar: {
      sizes: {
        xs: {
          container: {
            boxSize: 7,
          },
        },
        sm: {
          container: {
            boxSize: 9,
          },
        },
        md: {
          container: {
            boxSize: 11,
          },
        },
      },
    },
    Button: {
      defaultProps: {
        colorScheme: 'default',
      },
      baseStyle: {
        lineHeight: 'short',
        fontWeight: 'medium',
        _focus: {
          boxShadow: 'none',
        },
      },
      sizes: {
        lg: {
          h: 11,
          minW: 11,
          fontSize: 'md',
          px: 4,
        },
        md: {
          h: 9,
          minW: 9,
          fontSize: 'md',
          px: 3,
        },
        sm: {
          h: 7,
          minW: 7,
          fontSize: 'md',
          px: 3,
        },
        xs: {
          h: 5,
          minW: 5,
          fontSize: 'md',
          px: 0,
          borderRadius: 'sm',
        },
      },
      variants: {
        outline: ({ colorScheme }) => {
          if (colorScheme === 'default') {
            return {
              bg: 'transparent',
              color: 'cf.cntPrimary',
              borderColor: 'cf.brdBlackAlpha12',
              _hover: {
                bg: 'cf.bgBlackAlpha6',
              },
              _active: {
                bg: 'cf.bgBlackAlpha12',
              },
              _disabled: {
                opacity: 0.4,
              },
            };
          }

          return {};
        },
        ghost: ({ colorScheme }) => {
          if (colorScheme === 'default') {
            return {
              bg: 'transparent',
              color: 'cf.cntPrimary',
              _hover: {
                bg: 'cf.bgBlackAlpha6',
              },
              _active: {
                bg: 'cf.bgBlackAlpha12',
              },
              _disabled: {
                opacity: 0.4,
              },
            };
          }

          return {};
        },
        solid: ({ colorScheme }) => {
          if (colorScheme === 'accent') {
            return {
              bg: 'cf.bgAccent',
              color: 'cf.cntOnColor',
              _hover: {
                bg: 'cf.bgAccentHover',
              },
              _active: {
                bg: 'cf.bgAccentPressed',
              },
              _disabled: {
                opacity: 0.4,
              },
            };
          }

          if (colorScheme === 'negative') {
            return {
              bg: 'cf.bgLightNegative',
              color: 'cf.cntNegative',
              _hover: {
                bg: 'cf.bgLightNegativeHover',
              },
              _active: {
                bg: 'cf.bgLightNegativePressed',
              },
              _disabled: {
                opacity: 0.4,
              },
            };
          }

          if (colorScheme === 'blackAlpha') {
            return {
              bg: 'cf.bgBlackAlpha6',
              color: 'cf.cntPrimary',
              _hover: {
                bg: 'cf.bgBlackAlpha6',
              },
              _active: {
                bg: 'cf.bgBlackAlpha12',
              },
              _disabled: {
                opacity: 0.4,
              },
            };
          }

          return {};
        },
      },
    },
    Container: {
      baseStyle: {
        px: 5,
      },
    },
    FormLabel: {
      baseStyle: {
        mb: 1,
        pt: '1px',
        pb: '3px',
        fontSize: 'sm',
        lineHeight: 'short',
        color: 'cf.cntTertiary',
        fontWeight: 'medium',
        _invalid: {
          color: 'cf.cntNegative',
        },
        _active: {
          color: 'cf.cntAccent',
        },
        _focus: {
          color: 'cf.cntAccent',
        },
      },
    },
    FormHelperText: {
      baseStyle: {
        color: 'cf.cntTertiary',
        fontSize: 'sm',
        lineHeight: 'short',
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: 'bold',
      },
      sizes: {
        xl: {
          fontSize: '2xl',
          lineHeight: 'tall',
          fontWeight: 'bold',
        },
      },
    },
    Checkbox: {
      defaultProps: {
        colorScheme: 'teal',
      },
      baseStyle: {
        control: {
          _focus: {
            boxShadow: 'none',
          },
        },
      },
    },
    Radio: {
      defaultProps: {
        colorScheme: 'teal',
      },
    },
    Input: {
      defaultProps: {
        colorScheme: 'teal',
        focusBorderColor: 'cf.brdAccent',
        errorBorderColor: 'cf.brdNegative',
      },
      variants: {
        outline: {
          field: {
            _placeholder: {
              color: 'cf.cntEmpty',
            },
            bg: 'cf.bgPrimary',
            borderColor: 'cf.brdBlackAlpha12',
            _focus: {
              boxShadow: 'none',
            },
            _invalid: {
              boxShadow: 'none',
            },
          },
        },
      },
      sizes: {
        md: {
          field: {
            h: '44px',
          },
        },
      },
    },
    Select: {
      defaultProps: {
        colorScheme: 'teal',
        focusBorderColor: 'cf.brdAccent',
        errorBorderColor: 'cf.brdNegative',
      },
      variants: {
        outline: {
          field: {
            _placeholder: {
              color: 'cf.cntEmpty',
            },
            bg: 'cf.bgPrimary',
            borderColor: 'cf.brdBlackAlpha12',
            _focus: {
              boxShadow: 'none',
            },
            _invalid: {
              boxShadow: 'none',
            },
          },
        },
      },
      sizes: {
        md: {
          field: {
            h: '44px',
          },
        },
      },
    },
  },
});

export default extendTheme(cfChakraTheme);
